import $ from "jquery";
import lazyload from "lazyload";
import md5 from "blueimp-md5";
import selectize from "@selectize/selectize";

window.scriptHash = {};

$(document).ready(function() {
	new lazyload();
	$('.full-width-slider__nav button, .full-width-slider__nav li').on('click', function(){
		new lazyload();
	});

	if($('.app-banner').length == 0)
	{
		return;
	}
	if(window.location.pathname == '/match/')
	{
		createCookie('showappbanner', 'false', 'never');
		return;
	}
	// Don't need to show the banner on the status page.
	if ((readCookie('showappbanner') != 'false') && (window.location.pathname != '/status/') && (window.location.pathname != '/coupons/'))
	{
		setTimeout(function()
		{
			$('body').addClass('banner-visible');
		}, 500);
	}
	$('.app-banner .btn').on('click', function()
	{
		$('body').removeClass('banner-visible');
		createCookie('showappbanner', 'false', 'never');
	});

	$("input[name='password1']").on('input',function()
	{
		parameterCheck();
	});
})

// Had to move this out so it acts as a static function to be called upon when the password field is autofilled.
// #21960
function parameterCheck()
{
	$("#password_parameters").show();
	var input_value = $("input[name='password1']").val();
	var input_length = input_value.length;
	if ((input_length) >= 10)
	{
		$("#input_length_check").attr('src', '/_images/register/check.png');
	}
	else
	{
		$("#input_length_check").attr('src', '/_images/register/warning.png');
	}
	if (/[a-z]/.test(input_value) == true)
	{
		$("#input_lowercase_check").attr('src', '/_images/register/check.png');
	}
	else
	{
		$("#input_lowercase_check").attr('src', '/_images/register/warning.png');
	}
	if (/[A-Z]/.test(input_value) == true)
	{
		$("#input_uppercase_check").attr('src', '/_images/register/check.png');
	}
	else
	{
		$("#input_uppercase_check").attr('src', '/_images/register/warning.png');
	}
	if (/[0-9]/.test(input_value) == true)
	{
		$("#input_number_check").attr('src', '/_images/register/check.png');
	}
	else
	{
		$("#input_number_check").attr('src', '/_images/register/warning.png');
	}
	if (((input_length) >= 10) && (/[a-z]/.test(input_value) == true) && (/[A-Z]/.test(input_value) == true) && (/[0-9]/.test(input_value) == true))
	{
		$("#register_submit_button").attr('disabled', false);
	}
	else
	{
		$("#register_submit_button").attr('disabled', true);
	}
}

export function createCookie(name, value, hours)
{
	if(hours == 'never')
	{
		var expires = '; expires=Tue, 19 Jan 2038 03:14:07 GMT';
	}
	else if(hours)
	{
		var date = new Date();
		date.setTime(date.getTime() + (hours*60*60*1000));
		var expires = '; expires=' + date.toGMTString();
	}
	else
	{
		var expires = '';
	}
	document.cookie = name + '=' + value + expires + '; path=/';
}
window.createCookie = createCookie

function readCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for(var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') c = c.substring(1,c.length);
		if(c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	}
	return null;
}
window.readCookie = readCookie;

function DuplicateScript(){ Error.apply(this, arguments); this.name = "DuplicateScript"; }
DuplicateScript.prototype = Object.create(Error.prototype);

window.runOnce = function(key)
{
	if (window.scriptHash[key])
	{
		throw new DuplicateScript(`Script with key ${key} was already run`);
	}
	else
	{
		window.scriptHash[key] = true
	}
}

function jsChallenge(base_str, output_obj)
{
    var i = 0;
    while (true)
    {
            let hash = md5(base_str + i);
            if (hash.substr(0, 2) == '00')
            {
                    break;
            }
            i++;
    }
    output_obj.val(i);
}
window.jsChallenge = jsChallenge;
